<template>
    <vs-popup :title="'Print options'" :active="show" @close="$emit('close')">
        <p class="mb-6">{{ selected.length }} Will(s) selected</p>

        <div class="vx-col w-full">
            <div class="flex mb-3">
                <label for="" class="mr-auto">Separate Cover sheets</label>
                <vs-switch
                    color="success"
                    v-model="separateCoveringPage"
                    vs-icon="done"
                />
            </div>
        </div>
        <vs-divider></vs-divider>
        <div class="flex">
            <vs-button
                @click="download"
                iconPack="feather"
                icon="icon-download"
                class="mr-3 vs-con-loading__container"
                id="button-with-loading"
                >Download</vs-button
            >
            <vs-button
                @click="markAsPrinted"
                iconPack="feather"
                icon="icon-printer"
                class="mr-3"
                >Mark as printed</vs-button
            >
            <vs-button @click="$emit('close')" type="border">Cancel</vs-button>
        </div>
    </vs-popup>
</template>

<script>
import { http } from '@/services'
export default {
    name: 'PrintModal',
    props: ['show', 'selected'],
    mixins: [],
    watch: {},
    computed: {},
    data() {
        return {
            separateCoveringPage: false,
            downloadLoading: false,
        }
    },
    methods: {
        download() {
            if (this.separateCoveringPage) {
                var date = new Date()
                this.downloadWillsCoverLetters(date)
                this.downloadWillsWills(date)
            } else this.downloadWillsCombined()
        },
        downloadWillsCombined() {
            this.loadingDownload()
            var data = this.selected.map((will) => will.id)
            console.log('download the following', data)

            http.post(
                'wills_print_download',
                { ids: data },
                {
                    responseType: 'arraybuffer',
                }
            )
                .then((response) => {
                    const filename = `multi-wills-to-print-${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}.pdf`
                    this.openFile(response, filename)
                    this.loadingDownloadClose()
                })
                .catch((error) => {
                    console.log(error)
                    this.loadingDownloadClose()
                })
        },
        downloadWillsCoverLetters(date) {
            this.loadingDownload()
            var data = this.selected.map((will) => will.id)
            http.post(
                'wills_print_download_printing_letters',
                { ids: data },
                {
                    responseType: 'arraybuffer',
                }
            )
                .then((response) => {
                    const filename = `multi-wills(cover-letters)-to-print-${date.toLocaleDateString()}-${date.toLocaleTimeString()}.pdf`
                    this.openFile(response, filename)
                })
                .catch((error) => {
                    console.log(error)
                    this.loadingDownloadClose()
                })
        },
        downloadWillsWills(date) {
            this.loadingDownload()
            var data = this.selected.map((will) => will.id)
            http.post(
                'wills_print_download_wills',
                { ids: data },
                {
                    responseType: 'arraybuffer',
                }
            )
                .then((response) => {
                    const filename = `multi-wills(wills)-to-print-${date.toLocaleDateString()}-${date.toLocaleTimeString()}.pdf`
                    this.openFile(response, filename)
                    this.loadingDownloadClose()
                })
                .catch((error) => {
                    console.log(error)
                    this.loadingDownloadClose()
                })
        },
        openFile(response, filename) {
            const type = response.headers['content-type']
            const blob = new Blob([response.data], {
                type: type,
                encoding: 'UTF-8',
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            link.click()
        },
        loadingDownload() {
            this.$vs.loading({
                background: 'primary',
                color: '#fff',
                container: '#button-with-loading',
                scale: 0.45,
            })
        },
        loadingDownloadClose() {
            this.$vs.loading.close('#button-with-loading > .con-vs-loading')
        },
        markAsPrinted() {
            if (this.selected.length) {
                var data = this.selected.map((will) => will.id)

                http.post('wills_print_multiple', data)
                    .then((response) => {
                        this.$vs.notify({
                            text: 'Wills marked as printed',
                            color: 'success',
                        })
                        this.$emit('refresh')
                        this.$emit('close')
                        this.selected = []
                    })
                    .catch((error) => {
                        this.$vs.notify({
                            text: error.response.data,
                            color: 'danger',
                        })
                    })
            }
        },
    },
}
</script>
